import React from 'react';

function NotFoundPage() {
  return (
    <div>
      <h1>Page not found</h1>
      <p>Sorry, we couldn't find the page you were looking for.</p>
    </div>
  );
}

export default NotFoundPage;
