import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>Cat Heihei's Vision</h1>
      <p>We welcome our community’s love and ideas to help Cat Heihei’s dream come true.</p>
    </div>
  );
}

export default AboutPage;
